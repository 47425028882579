import BasePortableText from '@sanity/block-content-to-react';
import React from 'react';

import { LinkMark, InternalLinkMark } from 'types/schema';
import Link from 'components/Link';

import clientConfig from '../../client-config';

const serializers = {
  marks: {
    internalLink: ({ mark, children }: InternalLinkMark) => {
      const {
        item: { slug: { current: slug = '' } = {} },
      } = mark;
      const to = `/resources/${slug}/`;
      return <Link to={to}>{children}</Link>;
    },
    link: ({ mark, children }: LinkMark) => {
      const { href } = mark;
      if (href.startsWith('/')) {
        return <Link to={href}>{children}</Link>;
      } else {
        return <a href={href}>{children}</a>;
      }
    },
  },
};

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blocks: any[];
  className?: string;
};

const PortableText: React.FC<Props> = ({ blocks, ...props }) => (
  <BasePortableText blocks={blocks} serializers={serializers} {...clientConfig.sanity} {...props} />
);

export default PortableText;
